.card-item-parceiros {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Ajuste isso conforme a necessidade */
    width: 100%;  /* Evita esticar a imagem */
}

.card-header-parceiros img {
    max-width: 100%; /* Para garantir que as imagens sejam responsivas */
    height: auto; /* Mantém a proporção da imagem */
    object-fit: contain; /* Assegura que a imagem caiba no contêiner sem esticar */
}

.parceiros-texto-item .swiper{
    width: 100%;
}

.parceiros-texto-item img{
    border-radius: 10px;
}
