footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: max-content;
}

p{
    padding-top: 2%;
    margin-bottom: 2%;
    font-weight: 400;
    font-size: 20px;
    color: #F0ECE5;
}

.footer-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border-bottom: #CED1D5 1px solid;
    width: 76.5%;
    justify-content: center;
    gap: 10%;
}
.logo-footer{
    width: 63%;
    z-index: 8;
    margin-bottom: 3%;
}
.footer-item:nth-child(2){
    padding-top: 2.5%;
    z-index: 8;
}
.footer-item:nth-child(3){
    padding-top: 2.5%;
    z-index: 8;
}


.footer-item{
    display: flex;
    flex-direction: column;
    color: #F0ECE5;
    text-decoration: none;
    overflow-wrap: break-word;
    padding-bottom: 8%;
}


.footer-item ul{
    display: flex;
    flex-direction: column;
    list-style-type: none;
}
.footer-item ul li{
    margin-bottom: 6px;
}
.footer-item h3{
    font-weight: 400;
    font-size: 1.5rem;
    padding-bottom: 8%;
}
.footer-item p{
    font-size: 20px;
}
.icons{
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.foot-item{
    color: #F0ECE5;
    font-size: 1.25rem;
    font-weight: 300;
    text-decoration: none;
}
.foot-item:hover{
    border-bottom: #F0ECE5 2px solid;
}
.copyright{
    font-size: 1.125rem;
}

@media (max-width: 550px){
    .footer-container {
        grid-template-columns: 1fr; /* Define uma única coluna */
        grid-template-rows: auto auto; /* Define duas linhas automáticas */
      }
    
      .footer-item:first-child {
        grid-column: 1 / span 1; /* Ocupa a primeira coluna */
        grid-row: 1; /* Está na primeira linha */
      }
    
      .footer-item:nth-child(2), .footer-item:nth-child(3) {
        grid-column: 1; /* Ocupa a primeira coluna */
        grid-row: 2; /* Está na segunda linha */
        width: 50%; /* Ocupa metade da largura da linha */
        margin-bottom: 10%;
        padding-top: 0%;
      }
      .footer-item:nth-child(2) {
        margin-left: 53%;
        padding-top: 0%;
      }
      .logo-footer{
        width: 55%;
      }

      .copyright{
        margin-top: 1%;
        font-size: 12px;
        margin-bottom: 2%;
      }
}