.noticia-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    margin-bottom: 2rem; 
  }
  .normal {
    flex-direction: row;
  }
  
  .invertida {
    flex-direction: row-reverse;
  }

.noticias-container{
    margin-bottom: 20%;
    padding-top: 3.5rem;
}

.titulo-noticias{
    margin-top: 3%;
    margin-left: 14%;
    text-align: start;
}

.titulo-noticias h1{
    font-size: 64px;
    font-weight: 400;
    line-height: 77px;
    color: #FFFFFF;
    display: inline-block;
    border-bottom: 5px solid #FFFFFF;
}

.conteudo-noticias-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center baseline;
    justify-content: center;
    /*width: 82%;*/
    height: 50%;
    margin-top: 5%;
    margin-bottom: 8%;

    gap: 8%;
    margin-right: 12%;
    margin-left: 12%;
}

.figura-noticias-container{
    position: relative;
    width: 50%;
}
.figura-noticias{
    width: 100%;
    height: 91%;
    background-color: #171E3F;
    position: absolute;
    margin-left: 5%;
}
.enquadro-imagem-noticias{
    width: 90%;
    height: 90%;
    position: relative;
    overflow: hidden;
    transform: rotate(-4deg);

    margin-left: 12%;
}


.imagem-noticia{
    width: 100%;
    height: 100%;
}

.texto-container-noticias{
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    z-index: 6;
    position: relative;
}
.texto-imagem-noticias{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;

    font-weight: 600;
    font-size: 1.375rem;
    line-height: 33px;
    text-align: center;
}

.botao-expandir:hover{
    cursor: pointer;
    z-index: 6;
}
.expandido{
    overflow: visible !important;
    -webkit-line-clamp: unset !important;
}
.virado-baixo {
    transform: rotate(180deg);
  }
/* .copinho{
    position: absolute;
    width: 50%;
    top: 55%;
    left: 75%;
    z-index: 5;
} */
.luz-esquerda-noticias{
    position: absolute;
    left: -10%;
    width: 30%;
    top: 50%;
    z-index: 0;
}
.luz-direita-noticias{
    position: absolute;
    height: 75%;
    width: 75%;
    right: 0;
    top: 64%;
    z-index: 0;
}


@media (max-width: 1600px){
    .luz-direita-noticias{
        right: 0;
        width: 60%;
    }
}

@media (max-width: 1240px){
    .texto-container-noticias{
        margin-top: 10%;
     }

}

@media (min-width: 1101px){
    .noticia{
        min-width: 100%;
    }
}

@media (max-width: 1100px){
    .noticia-row {
        flex-direction: column;
        align-items: center;
      }
    .luz-direita-noticias{
        right: 0;
        width: 60%;
        top: 30%;
    }

    .conteudo-noticias-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 7%;
        margin-right: 7%;
        margin-top: 10%;
    }
    .figura-noticias-container{
        width: 90%;
        height: 50%;
    }
    .luz-esquerda-noticias{
        width: 60%;
        top: 20%;
    }
    .texto-container-noticias{
        width: 90%;
        height: 50%;
        margin-top: 15%;
    }
    /* .copinho{
        width: 50%;
        left: 70%;
        top: 70%;
    } */
}

@media (max-width: 600px){
    .noticia-row {
        flex-direction: column;
        align-items: center;
      }
    .titulo-noticias h1{
        font-size: 40px;
        margin-left: 0%;
    }
    .texto-container-noticias{
        margin-top: 25%;
        width: 100%;
        margin-left: 5%;
    }
    .texto-imagem-noticias{
        font-size: 18px;
        margin-left: 5%;
    }

    .luz-esquerda-noticias{
        display: none;
    }
    .luz-direita-noticias{
        width: 60%;
        left: 40%;
        top: 40%;
    }
}
