/*Seção de parceiros*/
.parceiros-container{
    display: flex;
    flex-direction: row;
    background-color: #B6BBC4;
    width: 100%;
    justify-content: space-between;
    margin-right: 12.5%;
    overflow: hidden !important;
}

.parceiros-texto-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
    width: 60%;
}
.parceiros-texto-item h1{
    font-weight: 400;
    font-size: 52px;
    line-height: 62.61px;
    color: #31304D;
    border-bottom: #31304D 7px solid;
    padding-bottom: 10px;

    margin-bottom: 5%;
}
.parceiros-texto-item p{
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #212121;

    margin-bottom: 7%;
}
.retangulo-esquerdo{
    margin-top: -20%;
}
.retangulo-direito-parceiros{
    margin-bottom: -10%;
}

@media (max-width: 768px){
    .retangulo-direito-parceiros{
        width: 25%;
        margin-bottom: -30%;
    }
    .retangulo-esquerdo{
        width: 20%;
    }
    .parceiros-container{
        height: max-content;
    }
}
