.swiper-button-prev::after {
    color: #CED1D5;
    font-size: 24px;
}
.swiper-button-next::after {
    color: #CED1D5;
    font-size: 24px;
}

.swiper-pagination-bullet{
    background-color:#D9D9D9;
    height: 12px;
    width: 12px;
}

.swiper-slide{
    display: grid;
}


.card-item{
    justify-self: center;
    align-self: center;
}

/*Media query*/
@media screen and (max-width: 767px) {
    .card-item:first-child {
        margin-left: auto;
        margin-right: auto;
    }
}
@media (max-width: 470px){
    .swiper-button-next::after {
        margin-right: -135%;
    }
    .swiper-button-prev::after {
        margin-left: -135%;
    }
}
@media (max-width: 415px){
    .card-item{
        width: 315px;
    }
}
@media (max-width: 390px){
    .card-item{
        width: 290px;
    }
}
