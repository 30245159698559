.home-container {
  margin-bottom: 5%;
  padding-top: 4.5rem;
}

/*seção de apresentao*/
.apresentacao-item {
  border-bottom: #ced1d5 1px solid;
  margin-left: 12.25%;
  margin-right: 12.25%;
}
.logo-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
}
.logo-home{
  width: 40%;
}

.conteudo-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 5%;
}

.texto-item {
  width: 60%;
}
.texto-item h1 {
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  color: #f0ece5;
}
.texto-item p {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}
.texto-item span {
  font-weight: 800;
  font-size: 22px;
  line-height: 33px;
}

.imagens-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
}

.fundo {
  position: absolute;
  width: 50%;
  height: 100%;
  overflow: hidden;
  background: radial-gradient(circle at center, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 5%, rgba(255,255,255,0) 65%, rgba(255,255,255,0) 100%); 
  pointer-events: none;
}
.iphone {
  position: relative;
  /*background: radial-gradient(circle at center, rgba(255,255,255,0) 1%, rgba(255,255,255,0.2) 10%, rgba(255,255,255,0) 64%, rgba(255,255,255,0) 100%); /*solução intermediária caso a atual dê bomba*/
}
.botoes-item {
  display: flex;
  flex-direction: row;
  gap: 2%;
}
button {
  width: max-content;
  margin-bottom: 5%;
}


@media screen and (max-width: 1350px) {
  .conteudo-item {
    flex-direction: column-reverse; /* Inverte a direção da coluna */
  }

  .texto-item {
    width: 100%; /* Define a largura total */
  }

  .imagens-item {
    width: 100%; /* Define a largura total */
    order: 1; /* Coloca as imagens antes do texto */
  }
  .fundo{
    width: 100%;
  }
  .iphone {
    position: static; /* Remove a posição relativa */
  }
  .texto-item p {
    margin-bottom: 5%;
  }
  button {
    z-index: 6;
  }
}

@media (max-width: 600px){
  .fundo{
    width: 85%;
    height: 85%;
  }
  .iphone{
    width: 100%;
  }
  .conteudo-botao {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 16px;
  }
}
@media (max-width: 470px){
  .apresentacao-item {
    margin-left: 8%;
    margin-right: 8%;
  }
  .restaurante-garcons-item{
    font-size: 25px;
  }
  .logo-item{
    margin-left: 5%;
  }
  .fundo {
    width: 100%;
    height: 90%;
  }
  .iphone{
    width: 110%;
  }
  
}
@media screen and (max-width: 440px){

  .texto-item h1{
    font-size: 35px;
    line-height: 1.3;
    margin-bottom: 10%;
  }
  .logo-item{
    margin-top: 1%;
  }
  .logo-item img:first-child{
    width: 100%;
  }
  .iphone{
    width: 120%;
  }
  .botoes-item{
    margin-bottom: 12%;
    margin-top: 5%;
  }
  .conteudo-botao {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 16px;
  }
  
}
