.redirex-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    
    width: 90%;
    margin-top: 3%;
    margin-bottom: 8%;
    margin-left: 4%;
}
.redirex-item-titulo{
    font-size: 60px;
    font-weight: 650;
    color: #FFFFFF;
    margin-bottom: 0%;
}
.redirex-item-conteudo{
    font-size: 22px;
    font-weight: 400;
    line-height: 38px;
    color:#FFFFFF;
}
.button-redirex-home{
    border: 2px solid #FFFFFF;
    background-color: #161A30;
    border-radius: 30px;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 400;
    height: min-content;
}
.button-redirex-home:hover{
    background-color: #161A30;
}

.fundo-redirex{
    z-index: 5;   
}

@media (max-width: 1200px){
    .redirex-item-titulo{
        font-size: 50px;
        font-weight: 600;
    }
    .fundo-redirex{
        width: 50%;
    }
}

@media (max-width: 900px){
    .redirex-item-titulo{
        font-size: 50px;
        font-weight: 600;
        text-align: center;
    }
    .redirex-item-conteudo{
        text-align: center;
    }
    
}

@media (max-width: 700px){
    .redirex-container{
        margin-bottom: 15%;
        margin-top: 10%;
        margin-left: 6%;
    }
    .redirex-item-titulo{
        font-size: 38px;
        font-weight: 500;
        margin-bottom: 3%;
    }
    .redirex-item-conteudo{
        font-size: 18px;
        font-weight: 350;
        width: 75%;
        margin-bottom: 3%;
    }
    .button-redirex-home{
        font-size: 12px;
    }
}