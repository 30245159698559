.campanhas-container{
    margin-bottom: 20%;
    padding-top: 3.5rem;
}

.campanha-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px; 
  }
  
  .normal {
    flex-direction: row; 
  }
  
  .invertida {
    flex-direction: row-reverse; 
  }

.titulo-campanhas{
    margin-top: 3%;
    text-align: center;
}

.titulo-campanhas h1{
    font-size: 64px;
    font-weight: 400;
    line-height: 77px;
    color: #FFFFFF;
    display: inline-block;
    border-bottom: 5px solid #FFFFFF;
}

.conteudo-campanhas-container{
    display: flex;
    flex-direction: column; 
    align-items: center;
    /*width: 82%;*/
    margin-top: 5%;
    margin-bottom: 8%;
    margin-right: 5%;
    margin-left: 8%;
    gap: 2rem;
}

.texto-container-campanhas{
    width: 60%;
    height: 50%;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 6;
    position: relative;
}

.texto-imagem-campanhas{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;

    font-weight: 600;
    font-size: 1.375rem;
    line-height: 33px;
    text-align: center;
}

.botao-expandir:hover{
    cursor: pointer;
}
.expandido{
    overflow: visible !important;
    -webkit-line-clamp: unset !important;
}
.virado-baixo {
    transform: rotate(180deg);
  }

.luz-campanhas{
    position: absolute;
    left: 0;
    width: 50%;
    z-index: 0;
}

.figura-campanhas-container{
    position: relative;
    width: 40%;
    height: 50%;
}
.enquadro-imagem-campanhas{
    width: 90%;
    height: 90%;
    position: relative;
    overflow: hidden;
    transform: rotate(-4deg);
    max-width: 100%;

    box-shadow: 30px 30px #171E3F;
}
.imagem-campanhas{
    width: 100%;
    height: 100%;
}
/*.microfone{
    position: absolute;
    top: 85%;
    right: 35%;
    width: 75%;
    z-index: 5;
}*/

/* @media (max-width: 2000px) {
    .microfone{
        top: 65%;
        right: 55%;
    }
} */

@media (min-width: 1101px){
    .campanha{
        min-width: 100%;
    }
}

@media (max-width: 1100px){
    .campanhas-container{
        margin-left: 12%;
        margin-right: 12%;
      }

    .conteudo-campanhas-container {
        flex-direction: column  !important;
        align-items: center;
        margin-left: 8%;
        width: 90%;
      }
      .campanha-row {
        flex-direction: column-reverse !important;
        align-items: center;
      }
      .conteudo-campanhas-container > :nth-child(1){
        width: 100%;
        
      }   
      .texto-container-campanhas{
        margin-top: 45%;
        margin-left: 0;
        width: 100%;
      }
    
      .figura-campanhas-container {
        margin-bottom: 5%;
        width: 100%;
      }
    /*   .microfone{
        top: 70%;
        right: 55%;
        width: 60%;
    } */
      
}

@media (max-width: 600px){
    .titulo-campanhas{
        text-align: start;
    }
    .titulo-campanhas h1{
        font-size: 40px;
    }
    .conteudo-campanhas-container{
        margin-top: 12%;
    }
    .texto-imagem-campanhas{
        font-size: 18px;
    }
    .enquadro-imagem-campanhas{
        box-shadow: 20px 20px #171E3F;
    }
    /* .microfone{
        right: 52%;
        width: 60%;
        top: 75%;
    } */
}
