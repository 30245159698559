@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500&family=Poppins:wght@200&family=Urbanist:ital,wght@0,100..900;1,100..900&family=Varela+Round&display=swap');
.urbanist {
    font-family: "Urbanist", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
  }

*{
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body{
  background: #161A30;
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
  overflow-x: hidden !important;
}

button{
  background-color: #F0ECE5;
  padding: 10px;
  font-size: 20px;
  font-weight: 600;
  border-radius: 10px;
  line-height: 24px;
  color: #171E3F;
  width: 186px;
  height: 3rem;
}

button:hover{
  background-color: #B6BBC4;
  cursor: pointer;
}

a {
  text-decoration: none; 
  color: inherit; 
}

a:hover {
  text-decoration: none; 
}