nav{
    width: 100%;
    height: 5rem;
    background-color: #161A30;
    position: fixed;
    z-index: 9;
    top:0;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
}
.nav-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    
    gap: 10%;
    padding-top: 0.17rem;
}
.nav-logo{
    width: 11rem;
}
.nav-menu {
    display: flex;
    gap: 12%;
    align-items: baseline;
    justify-content: flex-end;
    list-style-type: none;
    padding-top: 1%;
}

.nav-item{
    color: #F0ECE5;
    font-size: 18px;
    text-decoration: none;
}

.nav-item:hover{
    cursor: pointer;
    border-bottom: #F0ECE5 2px solid;
}

/*Mobile*/

.menu-icon{
    cursor: pointer;
    display: none;
}

.menu-icon .bar{
    display: block;
    width: 28px;
    height: 4.5px;
    border-radius: 3px;
    background: #F0ECE5;
    margin: 5px auto;
    transition: all 0.3s;
}

@media (max-width: 960px){
    .nav-container{
        padding-top: 0.6rem;
    }
    
    .menu-icon{
        display: block;
        z-index: 7;
        align-self: center;
    }
    .menu-ativo {
        overflow: hidden !important;
        transition: overflow 0.3s ease;
    }
    
    .menu-icon.ativo .bar:nth-child(1){
        transform: translateY(0.625rem) rotate(45deg);
    }

    .menu-icon.ativo .bar:nth-child(2){
        opacity: 0;
    }

    .menu-icon.ativo .bar:nth-child(3){
        transform: translateY(-0.531rem) rotate(-45deg);
    }
    .nav-menu{
        position: fixed;
        top: 0;
        right: 100%;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #161A30;
        transition: 0.3s;
        z-index: 7;
    }
    .nav-menu.ativo{
        right: 0;
    }
    .nav-item{
        margin: 16px 0;
    }
    .nav-menu:last-child{
        display: none;
    }
    
}