@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500&family=Poppins:wght@200&family=Urbanist:ital,wght@0,100..900;1,100..900&family=Varela+Round&display=swap');

.popup-container {
  background-image: linear-gradient(
    -155deg,
    #f0ece5 0%,
    #eae6e0 60%,
    #31304d 100%
  );
  border-radius: 30px;
  width: 50%;
  min-height: 50vh;
  left: 25%;
  top: 20%;
  z-index: 10;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  overflow: hidden;
}
.logo-pop-up{
  width: 55%;
}
.popup-container p {
  font-weight: 400;
  font-size: 2rem;
  line-height: 38.4px;
  color: #31304d;
  text-align: center;
  width: 85%;
  height: auto;
  text-wrap: wrap;
  margin-bottom: 4%;
}
.popup-container p span {
  font-weight: 800;
}

.popup-item {
  display: flex;
  flex-direction: row;
  width: 95%;
  justify-content:center;
  align-items: center;
  margin-top: 1%;
}
.popup-item > :nth-child(2) {
  margin-left: auto;
  margin-bottom: 9%;
}
.popup-item > :first-child{
  margin-left: auto;
  margin-top: 3%;
}
.button:hover {
  cursor: pointer;
}
.popup-item button img {
  width: 1.875rem;
  height: 1.875rem;
}

.formulario-container {
  display: flex;
  width: 85%;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.formulario-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.formulario-item input {
  width: 100%;
  height: 60px;
  background-color: #171e3f70;
  padding: 10px;
  border: 1px #f0ece5;
  box-shadow: #000000;
  border-radius: 1.5rem;
  border: none;
}
.formulario-item input::placeholder {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 28.8px;
  text-align: initial;
  color: #000000;
  font-family: 'Urbanist', sans-serif;
}

.formulario-container button {
  margin-bottom: 3%;
  font-weight: 800;
  background-color: #171e3f;
  border-radius: 30px;
  color: #f0ece5;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fundo escurecido */
  z-index: 9;
}

.formulario-item .error-message-popup {
  margin: 0;
  font-size: 1rem;
  color: #a94442;
  margin-left: 3%;
}

.formulario-item .success-message-popup {
  margin: 0;
  font-size: 1rem;
  color: #3c763d;
  margin-left: 3%;
}

@media (max-width: 2100px){
  .popup-container {
    min-height: 30vh;
  }
}

@media (max-width: 1000px) {

  .popup-container{
    height: fit-content;
    width: 70%;
    left: 15%;
  }
  .popup-item {
    width: 100%;
    justify-content: flex-end;
    flex-direction: column-reverse;
    margin-top: 5%;
    margin-left: 5%;
  }

  .popup-item .button {
    width: 30px;
    height: 30px;
    align-self: flex-end;
    margin-right: 10%;
    margin-top: 3%;
  }
  .popup-item > :first-child{
    margin-left: 0;
  }
  .popup-item > :nth-child(2){
    margin-bottom: 0;
  }
  .popup-container p{
    margin-right: 3%;
    margin-top: 5%;
    margin-bottom: 9%;
  }
  .logo-pop-up{
    width: 75%;
    margin-top: 5%;
    align-self: center;
  }
}