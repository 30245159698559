.dicas-container{
    width: 86%;
    align-items: center;
    justify-content: center;
    margin-left: 10%;
    padding-top: 3.5rem;
}
.titulo-dicas{
    font-size: 64px;
    font-weight: 400;
    line-height: 77px;
    color: #FFFFFF;
    border-bottom: #FFFFFF 5px solid;
    margin-left: 5%;
    width: 160px;
    margin-top: 3%;
}

.dedinho{
    position: absolute;
    width: 13%;
    top: 45%;
    left: 0;
    top: 40%;
    z-index: 5;
}

.dedinho-mobile{
    right: 0;
    position: absolute;
    display: none;
}

.cards-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5%;
    margin-top: 4.5%;
    margin-left: 15%;
}

.cards-item{
    display: flex;
    flex-direction: column;
    width: 310px;
    height: 380px;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 10%;
}
.cards-item h3{
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    line-height: 34px;
    margin-top: 10%;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 10%;
}
.cards-item p{
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin-left: 8%;
    margin-right: 8%;
}

@media (max-width: 2500px) {
    .cards-container {
        justify-content: center; 
        align-items: center; 
        margin: 4.5% auto; 
        max-width: 1240px; 
    }
}
@media (max-width: 1800px){
    .dedinho{
        width: 12%;
    }
}
@media (max-width: 1500px){
    .dedinho{
        width: 12%;
        top: 60%;
    }
}

@media (max-width: 1200px){
    .dedinho{
        width: 9%;
    }
}

@media (max-width: 800px){
    .cards-container{
        justify-content: center;
        align-items: center;
    }
    .dedinho{
        display: none;
    }
    .dedinho-mobile{
        display: inline;
        width: 15%;
        top: 50%;
    }
}

@media (max-width: 600px){
    .dicas-container{
        margin-left: 8%;
    }
    .titulo-dicas{
        font-size: 40px;
        width: 95px;
    }
    .dedinho-mobile{
        margin-top: 5%;
    }
}

@media (max-width: 395px){
    .dedinho-mobile{
        top: 64%;
        width: 20%;
    }
    .cards-container{
        margin-left: 2%;
    }
    .cards-item{
        width: 300px;
    }
}