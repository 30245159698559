/*Section motivos-container*/
.motivos-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden !important;
}
.porque-item{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: baseline;
}

.logo-porque{
    margin-left: 10px;
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;
    background: linear-gradient(180deg, rgba(240, 236, 229, 1), rgba(240, 236, 229, 0));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;

}

.porque-item span{
    font-weight: 600;
    font-size: 48px;
    line-height: 57.6px;
}
.restaurantes-garcons-item{
    font-weight: 400;
    font-size: 40px;
    line-height: 48.16px;
}
.logo-motivos-item{
    font-weight: 600;
    font-size: 48px;
    line-height: 57.79px;
    margin-left: 10px;
    background: linear-gradient(180deg, rgba(240, 236, 229, 1), rgba(240, 236, 229, 0));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;    
}
.restaurante-carrossel{
    width: 90%;
    margin-bottom: 5%;
}
.restaurante-carrossel p{
    text-align: baseline;
}
.garcon-carrossel{
    width: 90%;
    margin-bottom: 5%;
}
.garcon-carrossel p{
    text-align: baseline;
}
.card-item{
    display: flex;
    flex-direction: column;
    width: 348px;
    height: 408px;
    background-color: #F0ECE5;
    border-radius: 24px; 
    margin-bottom: 10%;
}
.card-header{
    display: flex;
    flex-direction: row;
    margin-left: 8%;
    margin-right: 6%;
    margin-top: 12%;
    gap: 15%;
}
.card-item p{
    margin-left: 8%;
    margin-right: 6%;
    margin-top: 6%;
    color: #171E3F;
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
}
.card-item p span{
    font-weight: 800;
}
/*.luz-lateral-direita{
    position: absolute;
    top: 80%;
    right: 0;
    width: 20%;
    height: 100%;
    background: radial-gradient(circle at center, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 5%, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 100%);
    pointer-events: none;
}*/
.luz-lateral-esquerda{
    position: absolute;
    top: 160%;
    left: -50%;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at center, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);
    pointer-events: none;
}
@media (max-width: 5250px){
    .porque-item span{
        font-size: 30px;
    }
    .logo-porque{
        font-size: 36px;
    }
    .luz-lateral-esquerda{
        width: 90%;
    }
}
@media (max-width: 440px){
    .porque-item span{
        font-size: 26px;
    }
    .logo-porque{
        font-size: 32px;
    }


}