/*Seção de entre em contato*/
.contact-container {
  background-color: #171e3f;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.texto-duvida {
  margin-left: 25%;
  position: absolute;
  font-size: 48px;
  font-weight: 400;
  line-height: 57.79px;
  color: #f0ece5;
}
.enquadro {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  min-height: 500px;
  max-height: 600px;
  position: relative;
}
.forms-container {
  display: flex;
  flex-direction: column;
  width: 440px;
  min-height: 500px;
  max-height: 550px;
  background-color: #f0ece5;
  border-radius: 25px;
  justify-content: center;
  align-items: center;

  box-shadow: 20px 25px #31304d;

  margin-left: 10%;
  z-index: 8.5;
  gap: 3%;
}

.forms-item {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

select {
  font-family: 'Urbanist', sans-serif;
  width: 400px;
  height: 55px;
  border-radius: 10px;
  background-color: #171e3f36;
  padding: 10px;
}
select {
  color: #171e3fa6 65%;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  border: none;
  width: 90%;
  /*olhar aqui o placeholder do select*/
}
input {
  width: 90%;
  height: 55px;
  border-radius: 10px;
  background-color: #171e3f36;
  border: none;
  padding: 10px;
  font-size: 18px;
}

textarea {
  resize: none;
  width: 90%;
  height: 90px;
  border-radius: 10px;
  background-color: #171e3f36;
  border: none;
  padding: 10px;
  font-size: 18px;
}

textarea::placeholder {
  font-family: 'Urbanist', sans-serif;
  color: #171e3fa6 65%;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
}
input::placeholder {
  font-family: 'Urbanist', sans-serif;
  color: #171e3fa6 65%;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
}
.button-forms {
  margin-top: 5%;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  background-color: #171e3f;
  border-radius: 10px;
  color: #ffffff;
  width: 140px;
}
.button-forms:hover {
  color: #171e3f;
}

.contact-item {
  width: 60%;
}

.imagem-fundo {
  position: relative;
  width: 60%;
  height: 100%;
}

.imagem-fundo:first-child {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100% !important;
  max-width: 200px;
}
.imagem-fundo img:nth-child(2) {
  position: absolute;
  bottom: 280px;
  left: 0px;
}
.imagem-fundo img:nth-child(3) {
  position: absolute;
  top: 35%;
  left: 80%;
}

.imagem-fundo p {
  color: #000000;
  font-size: 40px;
  font-weight: 100;
  font-style: italic;
  line-height: 48.16px;
  margin: 0;
  position: absolute;
  top: 30%;
  left: 63%;
}
.imagem-fundo p span {
  font-weight: 400;
  font-style: normal;
}

.error-message {
  margin: 0;
  align-self: flex-start;
  font-size: 1rem;
  color: #a94442;
  padding-left: 6%;
}

.success-message {
  margin: 0;
  align-self: flex-start;
  font-size: 1rem;
  color: #3c763d;
  padding-left: 6%;
}


@media (max-width: 1200px) {
  .contact-container {
    height: 850px;
  }
  .enquadro {
    right: 20%;
    top: 15%;
  }
}

@media (max-width: 800px) {
  .contact-container {
    height: 1000px;
  }
  .forms-container {
    box-shadow: none;
  }
  .enquadro {
    right: 40%;
    top: 27%;
  }
  .imagem-fundo img:nth-child(2) {
    bottom: 500px;
    left: 0px;
  }
  .imagem-fundo img:nth-child(3) {
    top: 35%;
    left: 50%;
  }
  .imagem-fundo p {
    top: 20%;
    left: 120%;
  }
}

@media (max-width: 670px){
  .imagem-fundo img:first-child {
    width: 518px;
    margin-top: 38%;
  }
  .imagem-fundo p {
    left: 55%;
  }
  .enquadro {
    width: 85%;
    right: 50%;
  }
}
@media (max-width: 500px){
  .imagem-fundo img:first-child {
    width: 390px;
    margin-top: 55%;
  }
  .enquadro {
    width: 85%;
    right: 55%;
  }
  .imagem-fundo p {
    left: 50%;
  }
}

@media (max-width: 400px){
  .imagem-fundo img:first-child {
    width: 350px;
    margin-top: 55%;
  }
}